import React from 'react'
import { createRoot } from 'react-dom/client'
import { createInertiaApp } from '@inertiajs/react'
import axios from 'axios'
import Rails from '@rails/ujs'

Rails.start()

const pages = import.meta.glob('../pages/**/*.jsx')

document.addEventListener('DOMContentLoaded', () => {
  const csrfToken = document.querySelector('meta[name=csrf-token]').content
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

  createInertiaApp({
    resolve: async (name) => {
      const page = (await pages[`../pages/${name}.jsx`]()).default

      return page
    },
    setup({ el, App, props }) {
      createRoot(el).render(<App {...props} />)
    },
  })
})
